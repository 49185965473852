import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../../components/layout';

import PrivateRoute from './../../../../components/s4n/AccountManagement/PrivateRoute';
import Wishlist from './../../../../components/s4n/AccountManagement/Commerce/Wishlist'

const User = () => {
  return (
    <Layout className={`wishlist__page`}>
       
        <PrivateRoute component={Wishlist} />

    </Layout>
  )
}

export default User;